import { useRef as u, useMemo as t } from "react";
function n(r) {
  const e = u({
    value: r,
    previous: r
  });
  return t(() => (e.current.value !== r && (e.current.previous = e.current.value, e.current.value = r), e.current.previous), [
    r
  ]);
}
export {
  n as $
};
